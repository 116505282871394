import { defineStore } from 'pinia'

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    visible: false,
  }),
  actions: {
    toggle() {
        this.visible = !this.visible
    }
  }
})
