<template>
  <div class="app text-gray-600 bg-slate-50">
    <router-view />
    <ModalWindow />
  </div>
</template>

<script>
import { useAuthStore } from 'src/stores/auth'

import ModalWindow from 'src/components//ModalWindow.vue'

export default {
  name: 'App',
  components: {
    ModalWindow
  },
  setup() {
    const authStore = useAuthStore()

    authStore.onStartUp()

    return {  }
  }
}
</script>

<style>
.app {
  max-width: 500px;
  margin: auto;
  position: relative;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
