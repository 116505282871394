import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'

import { useAuthStore } from 'src/stores/auth'
import { useNavigationStore } from 'src/stores/navigation'
// import { useTransactionStore } from 'src/stores/transaction'
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const createHistory = process.env.SERVER ? createMemoryHistory : process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory
const Router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(process.env.VUE_ROUTER_BASE)
})

Router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const navigationStore = useNavigationStore()
  navigationStore.visible = false
  // const transactionStore = useTransactionStore()

  let isAuthenticated = authStore.access_token != null

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' })
  } else if ((to.name == 'Login' || to.name == 'Home') && isAuthenticated) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
})

export default Router
