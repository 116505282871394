const routes = [
  {
    path: '/dashboard',
    component: () => import('layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('pages/DashboardPage.vue')
      },
      {
        path: '/stats',
        name: 'Statistics',
        component: () => import('pages/StatsPage.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/LoginLayout.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('pages/IndexPage.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('pages/LoginPage.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('pages/ForgotPasswordPage.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
