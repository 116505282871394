import { defineStore } from 'pinia'
import { LocalStorage } from 'quasar'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    access_token: null,
    refresh_token: null,
    auth_expires_at: null
  }),
  actions: {
    setTokens(response) {
      this.access_token = response.access_token
      this.refresh_token = response.refresh_token
    },
    onStartUp() {
      this.access_token = LocalStorage.getItem('auth_token')
      this.auth_refresh_token = LocalStorage.getItem('auth_refresh_token')
      this.auth_expires_at = LocalStorage.getItem('auth_expires_at')
    }
  }
})
