import { defineStore } from 'pinia'
import de from 'src/i18n/de'

export const useModalStore = defineStore('modal', {
  state: () => ({
    visible: false,
    title: '',
    content: '',
    close: true
  }),
  actions: {
    toggleModal(title, content, close) {
      if (this.visible) {
        this.closeModal()
      } else {
       this.openModel(title, content, close)
      }
    },
    openModel(title, content, close) {
      this.visible = true
      this.title = title
      this.content = content
      this.close = close
    },

    setContent(titel, content) {
      this.title = titel
      this.content = content
    },
    closeModal() {
      this.visible = false
      this.content = ''
      this.close = close
    }
  }
})
