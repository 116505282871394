<template>
  <div class="modal" :class="modalClass">
    <div class="modal-window">
      <button v-if="modalStore.close" @click="closeModal">close</button>
      <h2>{{ modalStore.title }}</h2>
      <p>{{ modalStore.content }}</p>
    </div>
    <div class="modal-overlay"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useModalStore } from 'src/stores/modal'

const modalStore = useModalStore()
const { visible } = storeToRefs(modalStore)
const modalClass = ref('')
const closeModal = () => {
  modalStore.toggleModal('')
}

modalStore.$subscribe((mutation, state) => {
  modalClass.value = visible.value ? 'active' : ''
})
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transform: scale(0);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    z-index: 1000;
    transform: scale(1);
  }
}

.modal-window {
  background: $light;
  z-index: 1002;
  margin: 2rem;
  padding: 2rem;
  width: calc(100% - 4rem);
  border-radius: $br-2;
}

.modal-overlay {
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  backdrop-filter: blur(100px);
}
</style>
